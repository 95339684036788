import React, { useEffect } from 'react';
import { useTranslation } from '../../hooks/useTranslation';
import Typography from '@material-ui/core/Typography';
import {
  Grid,
  Box,
  Paper,
  Card,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core/';
import PaperBox from '../../components/utility/paper-box';
import ROUTES from '../../routes';
import { navigate, useLocation } from '@reach/router';
import PGEButton from '../../components/buttons';
import * as queryString from 'query-string';
import Alerts from '../../components/alerts/index';
import colors from '../../themes/main-colors';
import { getFromStorage, setInStorage } from '../../util/storage-utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    comfirmEmailPage: {
      margin: '70px auto',
      color: colors.noirBlur,
      [theme.breakpoints.down('xs')]: {
        margin: '16px',
      },
      '& h2': {
        fontWeight: 300,
        margin: '-15px 0 0',
      },
    },
    doneBtnHolder: {
      paddingTop: 10,
      display: 'flex',
      flexDirection: 'row-reverse',
    },
  }),
);

type QueryParams = {
  emailAddress: string;
};

export const ChangeEmailConfirmation = () => {
  const location = useLocation();
  const { t, richT } = useTranslation();
  const classes = useStyles();
  const queryParams = queryString.parse(location.search) as QueryParams;
  const updatedEmailAddress = queryParams.emailAddress;

  useEffect(() => {
    const userEmail = getFromStorage('userEmail');
    if (userEmail) {
      setInStorage('userEmail', updatedEmailAddress);
    }
  }, []);

  return (
    <>
      <Grid container justify="center" alignItems="center">
        <Grid item xs={11} sm={8} md={6}>
          <Paper component={'main'} className={classes.comfirmEmailPage}>
            <Card>
              <Alerts
                disableColorOverride={true}
                isOpen
                severity={'success'}
                variant={'outlined'}
                title={''}
                message={
                  <>
                    <Grid container spacing={2}>
                      <Grid item>
                        <Typography component="span">
                          {richT('CHANGE_EMAIL_CONFIRMATION', {
                            emailAddress: updatedEmailAddress,
                          })}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid className={classes.doneBtnHolder}>
                      <PGEButton
                        onClick={async () => {
                          await navigate(ROUTES.SIGN_IN, {
                            state: { newEmail: updatedEmailAddress },
                          });
                        }}
                      >
                        <Typography variant={'h4'} noWrap>
                          {t('DONE')}
                        </Typography>
                      </PGEButton>
                    </Grid>
                  </>
                }
              />
            </Card>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default ChangeEmailConfirmation;
